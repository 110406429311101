body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: white;
  font-family: 'Roboto', sans-serif;
  color: #000000;
}

.container {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 15px;
}

.question {
  font-size: 3em;
  font-weight: 700;
  margin: 20px auto;
  text-align: center;
  min-height: 120px;
}

.dropdown {
  padding: 10px 20px;
  font-size: 1.2em;
  margin-bottom: 30px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgb(239, 239, 239);
}

.options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.button {
  padding: 15px 30px;
  font-size: 1.2em;
  font-weight: 700;
  background: #ff4081;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin: 10px 0;
}

.button:hover {
  background: #ff79a9;
}

.button.small {
  padding: 5px 10px;
  font-size: 0.9em;
  font-weight: 300;
  background: white;
  color: black;
}

.button-difficulty {
  text-align: center;
}

.button-difficulty button {
  background-color: transparent;
  border: 0.5px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 16px;
  margin-top: 20px;
}

.button-difficulty button:hover {
  background-color: white;
  color: #356277;
}

.button-difficulty button.active {
  border: 2px solid rgb(0, 0, 0);
  color: #356277;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
}

.modal-content h2 {
  margin-top: 0;
  text-align: center;
  color: #333333;
}

.modal-list {
  padding-left: 20px;
  margin-top: 20px;
}

.modal-list li {
  font-size: 1em;
  color: #333333;
  line-height: 1.6;
  margin-bottom: 10px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5em;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
}

.close-button:hover {
  color: #ff4081;
}

.hidden {
  display: none;
}

/* Mobile Optimization */
@media (max-width: 480px) {
  body {
    padding: 10px;
    height: auto;
  }

  .container {
    width: 100%;
    padding: 20px;
    box-shadow: none;
  }

  .question {
    font-size: 2em;
    min-height: 100px;
    margin: 10px 0;
  }

  .dropdown {
    padding: 8px 15px;
    font-size: 1em;
    margin-bottom: 20px;
  }

  .options {
    flex-direction: column;
    gap: 10px;
  }

  .button {
    padding: 10px 20px;
    font-size: 1em;
  }

  .button-difficulty {
    display: flex;
    flex-direction: column;
  }

  .button-difficulty button {
    margin: 5px 0;
    padding: 8px 15px;
    font-size: 14px;
  }

  .modal-content {
    width: 90%;
    max-width: 100%;
    max-height: 90%;
    padding: 15px;
    border-radius: 10px;
  }

  .modal-content h2 {
    font-size: 1.5em;
  }

  .modal-list {
    padding-left: 10px;
    margin-top: 15px;
  }

  .modal-list li {
    font-size: 0.9em;
  }

  .close-button {
    font-size: 1.2em;
  }
}
/**
 * Expandable Content
 */
.expandable {
  max-width: 400px;
  width: 100%;
  margin-top: 20px;
}

.expandable-btn {
  width: 100%;
  padding: 10px 0;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  color: #007bff;
  background-color: transparent;
  border: none;
  outline: none;
}

.expandable-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: white;
  padding: 0 15px;
  border-radius: 0 0 6px 6px;
}

.expandable-content p {
  margin: 15px 0;
}

#arrow {
  display: inline-block;
  transition: transform 0.3s;
}

.expanded #arrow {
  transform: rotate(180deg);
}

footer {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #333;
}

footer a {
  color: #007bff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
